<template>
  <div>
    <b-datetimepicker
      v-bind="dateRange"
      ref="datetimepicker"
      v-model="datetime"
      size="is-small"
      expanded
      editable
      locale="de-DE"
      :disabled="field.properties.basic.isDisabled"
      :placeholder="field.properties.basic.hasPlaceholder
        ? formatPlaceholder(field.properties.basic.placeholder)
        : ''
      "
      :datetime-formatter="dateTimeFormatter"
      :datetime-parser="dateTimeParser"
      :timepicker="{
        hourFormat,
        disabled: !field.properties.basic.hasTimePicker,
      }"
      horizontal-time-picker
    >
      <template #left>
        <b-button
          label="Jetzt"
          type="is-primary"
          icon-left="clock"
          @click="selectCurrentDateTime"
        />
      </template>
      <template #right>
        <div class="is-flex is-flex-direction-column is-align-items-center">
          <b-button
            label="Löschen"
            type="is-danger"
            icon-left="close"
            outlined
            @click="clearDateTime"
          />
          <b-button
            v-if="field.properties.basic.hasTimePicker"
            label="Ok"
            type="is-primary"
            icon-left="check"
            class="mt-5"
            @click="datetimepicker.toggle()"
          />
        </div>
      </template>
    </b-datetimepicker>
  </div>
</template>

<script >
// libs
import { computed, ref, watch, onMounted } from '@vue/composition-api';
import moment from 'moment-timezone';
import Inputmask from 'inputmask';
// others
import { parseExpression } from '@/helpers/ExpressionParser';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const datePickerFormat = props.field.properties.basic.format || 'DD.MM.YYYY';
  const datetimepicker = ref(null);
  const isCurrentDateTime = ref(false);
  const datetime = computed({
    get() {
      const inputDate = props.values[props.field.id];
      if (inputDate) {
        return typeof inputDate === 'string' ? new Date(inputDate) : inputDate;
      }
      return null;
    },
    async set(value) {
      let updatedDate = value;
      if (updatedDate instanceof Date && props.field.properties.validation.hasRestriction) {
        const minDate = await dateTimeRange.value.minDate;
        const maxDate = await dateTimeRange.value.maxDate;
        if (minDate && updatedDate.getTime() < minDate.getTime()) {
          updatedDate = minDate;
        } else if (maxDate && updatedDate.getTime() > maxDate.getTime()) {
          updatedDate = maxDate;
        }
      }
      if (updatedDate && !props.field.properties.basic.hasTimePicker || isCurrentDateTime.value) {
        datetimepicker.value.toggle();
      }
      emit('update', {
        value: updatedDate
      });
      isCurrentDateTime.value = false;
    }
  });
  const dateTimeRange = computed(() => {
    const {
      properties: {
        validation: {
          isRestrictedFromDynamic,
          restrictedFrom,
          restrictedTo,
          isRestrictedToDynamic
        }
      }
    } = props.field;
    const computedMinDate = computeDateTimeLimit(restrictedFrom, isRestrictedFromDynamic);
    const computedMaxDate = computeDateTimeLimit(restrictedTo, isRestrictedToDynamic);
    return {
      ...(computedMinDate ? {
        minDate: computedMinDate
      } : {}),
      ...(computedMaxDate ? {
        maxDate: computedMaxDate
      } : {})
    };
  });
  const dateRange = computed(() => {
    const range = {};
    const {
      minDate,
      maxDate
    } = dateTimeRange.value;
    if (minDate instanceof Date) {
      range.minDatetime = new Date(minDate);
      range.minDatetime.setHours(0, 0, 0, 0);
    }
    if (maxDate instanceof Date) {
      range.maxDatetime = new Date(maxDate);
      range.maxDatetime.setHours(0, 0, 0, 0);
    }
    return range;
  });
  const hourFormat = computed(() => {
    const fullFormat = props.field.properties.basic.format;
    if (fullFormat?.indexOf('h') !== -1) {
      return '12';
    } else {
      return '24';
    }
  });
  const computeDateTimeLimit = (rawValue, isDynamic) => {
    /** @type {Date | null} */
    let datePickerLimit = null;
    if (isDynamic) {
      datePickerLimit = parseExpression(rawValue, props.values, 'strip').value;
    } else {
      datePickerLimit = rawValue || null;
    }
    if (datePickerLimit && typeof datePickerLimit === 'string') {
      // converting date string to Date object as required by date picker
      const userTimeZone = moment.tz.guess();
      datePickerLimit = moment.tz(datePickerLimit, userTimeZone).toDate();
    }
    return datePickerLimit;
  };
  const dateTimeFormatter = time => {
    const userTimeZone = moment.tz.guess();
    const outputDate = moment.tz(time, userTimeZone);
    return outputDate.format(datePickerFormat);
  };
  const dateTimeParser = dateString => {
    const userTimeZone = moment.tz.guess();
    let momentObj = moment.tz(dateString, datePickerFormat, userTimeZone);
    if (!props.field.properties.basic.hasTimePicker) {
      momentObj = momentObj?.startOf('day');
    }
    return momentObj.toDate();
  };
  const formatPlaceholder = dateString => {
    const date = moment(dateString);
    if (date.isValid()) {
      return date.format(datePickerFormat);
    } else {
      return dateString;
    }
  };
  const updateMask = () => {
    if (props.field.properties.basic?.format) {
      const dateTimeFormat = props.field.properties.basic.format.toUpperCase();
      let maskValue = '';
      for (let char of dateTimeFormat) {
        switch (char) {
          case 'M':
          case 'D':
          case 'Y':
          case 'H':
          case 'S':
            maskValue += '9';
            break;
          case 'A':
            maskValue += 'AM';
            break;
          case 'P':
          default:
            maskValue += char;
            break;
        }
      }
      const inputMaskInstance = new Inputmask({
        mask: maskValue
      });
      const inputElement = datetimepicker.value?.$el.querySelector('input');
      if (inputElement) {
        inputMaskInstance.mask(inputElement);
      }
    }
  };
  const selectCurrentDateTime = () => {
    isCurrentDateTime.value = true;
    let currentDate = moment();
    if (!props.field.properties.basic.hasTimePicker) {
      currentDate = currentDate.startOf('day');
    }
    datetime.value = currentDate.toDate();
  };
  const clearDateTime = () => {
    datetime.value = null;
  };
  const toggleDatePicker = () => {
    const timepickerElement = datetimepicker.value?.$el.querySelector('.timepicker');
    if (timepickerElement) {
      if (props.field.properties.basic.hasTimePicker) {
        timepickerElement.style.display = 'block';
      } else {
        timepickerElement.style.display = 'none';
      }
    }
  };
  onMounted(() => {
    updateMask();
    toggleDatePicker();
  });
  watch(() => props.field.properties.basic.format, () => {
    updateMask();
  }, {
    immediate: true,
    deep: true
  });
  return {
    datetimepicker,
    datetime,
    dateRange,
    hourFormat,
    dateTimeFormatter,
    dateTimeParser,
    formatPlaceholder,
    selectCurrentDateTime,
    clearDateTime
  };
};
export default __sfc_main;
</script>

<style lang="scss">
.datepicker-footer{
    .level{
        display: flex;
        flex-direction: column;
        .level-item {
            margin-right: 0 !important;
        }
    }
    button {
        width: fit-content;
    }
}
</style>
